/* eslint-disable import/no-anonymous-default-export */
export default [
    {
        name: 'Restaurant',
        buttonName: 'None'
    },
    {
        name: 'Food',
        buttonName: 'None'
    },
    {
        name: 'Bar',
        buttonName: 'None'
    },
    {
        name: 'Health & Beauty',
        buttonName: 'None'
    },
    {
        name: 'Shopping',
        buttonName: 'None'
    },
    {
        name: 'Events',
        buttonName: 'None'
    },
    {
        name: 'Entertainment',
        buttonName: 'None'
    },
    {
        name: 'Automotive',
        buttonName: 'None'
    },
    {
        name: 'Contractors',
        buttonName: 'None'
    }
]