/* eslint-disable import/no-anonymous-default-export */
// was "export default"
module.exports = [
    {
        id: 53,
        name: 'Elite Piano Studios',
        category: 'Contractors',
        subcategory: 'Piano Instructor',
        picturePath: '/images/elitepiano.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1656855009/businessPictures/ElitePianoStudios/ep1_n2urz6.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1656855010/businessPictures/ElitePianoStudios/ep3_x4ipls.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1656855009/businessPictures/ElitePianoStudios/ep2_avxl0r.jpg',
        description: 'Elite Piano Studios is proud to offer state of the art facilities to our students. Lessons are taught in acoustically engineered rooms with 14 foot ceilings and modern design. All piano lessons are taught on Grand Piano\'s. Nowhere in Southwestern Ontario will you find music studios that offer the experience and quality of teaching that Elite Piano Studios provides.  Really beautiful and fun higher-end music school featuring piano, guitar, voice, drum & production lessons!',
        phoneNumber: '519-999-8903',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'lisa@elitepianostudios.com',
        websiteURL: 'https://www.elitepianostudios.com',
        facebookURL: 'https://www.facebook.com/ElitePianoStudios',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: '3315 Banwell Rd, Windsor, ON N8N 0B6',
        googleMapsLink: 'https://goo.gl/maps/GBLuFnxP3syAv5HS9',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11802.735474990466!2d-82.9000082!3d42.3066105!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xba579c3cc97880f2!2sElite%20Piano%20Studios!5e0!3m2!1sen!2sca!4v1656854789853!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Monday - Friday:',
            second: 'Saturday - Sunday:'
        },
        hoursTimes: {
            first: '2:00pm to 7:00pm',
            second: 'CLOSED'
        },
        openingHours: {
            sunday: 'Closed',
            monday: '14:00',
            tuesday: '14:00',
            wednesday: '14:00',
            thursday: '14:00',
            friday: '14:00',
            saturday: 'Closed'
        },
        closingHours: {
            sunday: 'Closed',
            monday: '19:00',
            tuesday: '19:00',
            wednesday: '19:00',
            thursday: '19:00',
            friday: '19:00',
            saturday: 'Closed'
        },
        openPastMidnight: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        },
    },
    {
        id: 52,
        name: 'Suzie\'s Grill Cafe',
        category: 'Restaurant',
        subcategory: 'Breakfast',
        picturePath: '/images/suzies.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1656694905/businessPictures/SuziesGrillCafe/suzie1_pbdk0m.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1656694905/businessPictures/SuziesGrillCafe/suzie2_d6x2tl.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1656694905/businessPictures/SuziesGrillCafe/suzie3_shsnak.jpg',
        description: 'Family owned and operated establishment determined to make your dining experience an unforgettable one. Serving the city of Windsor delicious homemade meals since 2011.',
        phoneNumber: '519-969-0006',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: '',
        facebookURL: 'https://www.facebook.com/suzisgrillcafe/?ref=page_internal',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/suziesgrill_cafe/',
        instagramUsername: 'suziesgrill_cafe',
        instagramURL2: '',
        instagramUsername2: '',
        address: '2565 Ouellette Ave Suite 120, Windsor, ON N8X 1L9',
        googleMapsLink: 'https://goo.gl/maps/ZaJEuK8rDPmWddB37',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11806.899344009875!2d-83.0197908!3d42.2843968!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf8c009dff83df23a!2sSuzie%E2%80%99s%20Grill%20Cafe!5e0!3m2!1sen!2sca!4v1656695293112!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Monday:',
            second: 'Tuesday - Sunday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '8:00am to 3:00pm',
        },
        openingHours: {
            sunday: '08:00',
            monday: 'Closed',
            tuesday: '08:00',
            wednesday: '08:00',
            thursday: '08:00',
            friday: '08:00',
            saturday: '08:00'
        },
        closingHours: {
            sunday: '15:00',
            monday: 'Closed',
            tuesday: '15:00',
            wednesday: '15:00',
            thursday: '15:00',
            friday: '15:00',
            saturday: '15:00'
        },
        openPastMidnight: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        },
    },
    {
        id: 51,
        name: 'Toast',
        category: 'Restaurant',
        subcategory: 'Breakfast',
        picturePath: '/images/toast.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1656694355/businessPictures/Toast/toast1_hwmuq7.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1656694355/businessPictures/Toast/toast2_dticgu.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1656694355/businessPictures/Toast/toast3_uyf1dg.jpg',
        description: 'Awesome daytime eatery serving sandwiches & breakfast classics like waffles & eggs Benedict.',
        phoneNumber: '519-915-1023',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: '',
        facebookURL: 'https://www.facebook.com/TOAST2016/?ref=page_internal',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/toast_restaurant/',
        instagramUsername: 'toast_restaurant',
        instagramURL2: '',
        instagramUsername2: '',
        address: '653 Erie St E, Windsor, ON N9A 3Y1',
        googleMapsLink: 'https://goo.gl/maps/3hdNV9VZJBj5csf9A',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.4176101670146!2d-83.02614728255615!3d42.3122908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2cef0cbcaab3%3A0x216d9a92900cf12d!2sTOAST!5e0!3m2!1sen!2sca!4v1656694451356!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Monday:',
            second: 'Tuesday - Sunday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '8:00am to 3:00pm',
        },
        openingHours: {
            sunday: '08:00',
            monday: 'Closed',
            tuesday: '08:00',
            wednesday: '08:00',
            thursday: '08:00',
            friday: '08:00',
            saturday: '08:00'
        },
        closingHours: {
            sunday: '15:00',
            monday: 'Closed',
            tuesday: '15:00',
            wednesday: '15:00',
            thursday: '15:00',
            friday: '15:00',
            saturday: '15:00'
        },
        openPastMidnight: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        },
    },
    {
        id: 50,
        name: 'Rico Taco Windsor',
        category: 'Restaurant',
        subcategory: 'Mexican',
        picturePath: '/images/ricotaco.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1656693519/businessPictures/RicoTaco/rico1_bgleub.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1656693519/businessPictures/RicoTaco/rico2_vueaoc.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1656693519/businessPictures/RicoTaco/rico3_zxfrp8.jpg',
        description: 'Rico Taco Mexican Street Eats is a local mobile business serving fresh traditional mexican street food.\nProudly to be the first to serve Birria Tacos and freshly made Mexican churros in Windsor, Ontario!! We like it like that!',
        phoneNumber: '519-564-3325',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'ricotacowindsor@gmail.com',
        websiteURL: 'https://ricotacowindsor.square.site',
        facebookURL: 'https://www.facebook.com/ricotacowindsor',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/ricotacowindsor/',
        instagramUsername: 'ricotacowindsor',
        instagramURL2: '',
        instagramUsername2: '',
        address: '400 Erie St E, Windsor, ON N9A 3X4',
        googleMapsLink: 'https://goo.gl/maps/ovq3DFZKb7ACsD1RA',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11801.86370155379!2d-83.0277878!3d42.3112601!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x468fe885ff124bf8!2sRico%20Taco%20Windsor!5e0!3m2!1sen!2sca!4v1656693134519!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Aside from these standard hours, follow our Instagram page to see when we have pop-ups!',
        hoursDays: {
            first: 'Monday - Wednesday',
            second: 'Thursday - Friday:',
            third: 'Saturday:',
            fourth: 'Sunday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '5:00pm to 9:00pm',
            third: '12:00pm to 9:00pm',
            fourth: '12:00pm to 8:00pm'
        },
        openingHours: {
            sunday: '09:00',
            monday: 'Closed',
            tuesday: 'Closed',
            wednesday: 'Closed',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '20:00',
            monday: 'Closed',
            tuesday: 'Closed',
            wednesday: 'Closed',
            thursday: '21:00',
            friday: '21:00',
            saturday: '21:00'
        },
        openPastMidnight: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        },
    },
    {
        id: 49,
        name: 'Whamburg',
        category: 'Restaurant',
        subcategory: 'Burgers',
        picturePath: '/images/whamburg.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1649521470/businessPictures/Whamburg/whamburg1_yx19px.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1649521471/businessPictures/Whamburg/whamburg3_eqa1ua.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1649521471/businessPictures/Whamburg/whamburg2_q0kkq2.jpg',
        description: 'Whamburg is Windsor\'s finest Smashed Burger Joint. We\'re serving the best smash burgers, fries, and shakes the city has to offer! Our ingredients are fresh and locally sourced. Located on the University of Windsor campus.',
        phoneNumber: '519-800-0373',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: 'https://www.whamburg.com',
        facebookURL: 'https://www.facebook.com/whamburg/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/whamburg.yqg/?hl=en',
        instagramUsername: 'whamburg.yqg',
        instagramURL2: '',
        instagramUsername2: '',
        address: '401 California Ave, Windsor, ON N9B 3A9',
        googleMapsLink: 'https://goo.gl/maps/wtU2gNw1gASA43Gk7',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11802.657536779227!2d-83.0657487!3d42.3070262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x95a9512d431986c2!2sWhamburg!5e0!3m2!1sen!2sca!4v1649521307365!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Sunday - Thursday:',
            second: 'Friday - Saturday:',
        },
        hoursTimes: {
            first: '11:30am to 9:00pm',
            second: '11:30am to 10:00pm',
        },
        openingHours: {
            sunday: '11:30',
            monday: '11:30',
            tuesday: '11:30',
            wednesday: '11:30',
            thursday: '11:30',
            friday: '11:30',
            saturday: '11:30'
        },
        closingHours: {
            sunday: '21:00',
            monday: '21:00',
            tuesday: '21:00',
            wednesday: '21:00',
            thursday: '21:00',
            friday: '22:00',
            saturday: '22:00'
        },
        openPastMidnight: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        },
    },
    {
        id: 48,
        name: 'Sidelines',
        category: 'Bar',
        subcategory: 'Sports Bar',
        picturePath: '/images/sidelines.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1649520959/businessPictures/Sidelines/sidelines1_zlan1r.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1649520959/businessPictures/Sidelines/sidelines2_xlut1l.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1649520959/businessPictures/Sidelines/sidelines3_sxn1d0.jpg',
        description: 'Our menu features a variety of Specialty Burgers, Poutines, Drinks and more! An amazing atmosphere with all your favourite Sport teams on full display!',
        phoneNumber: '519-962-7433',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'contact@sidelineswindsor.com',
        websiteURL: 'https://www.sidelineswindsor.com',
        facebookURL: 'https://www.facebook.com/SidelinesWin/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/sidelineswindsor/?hl=en',
        instagramUsername: 'sidelineswindsor',
        instagramURL2: '',
        instagramUsername2: '',
        address: '2675 Lauzon Rd, Windsor, ON N8T 2Z5',
        googleMapsLink: 'https://goo.gl/maps/nkbSaB6MsnSjfxoD9',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11801.962983700318!2d-82.9304499!3d42.3107306!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x582cfc2d636890da!2sSidelines%20Sports%20Bar%20%26%20Grill!5e0!3m2!1sen!2sca!4v1649520161199!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Monday - Wednesday:',
            second: 'Thursday AND Sunday:',
            third: 'Friday - Saturday:'
        },
        hoursTimes: {
            first: '3:00pm to Midnight',
            second: '12:00pm to Midnight',
            third: '12:00pm to 2:00am'
        },
        openingHours: {
            sunday: '12:00',
            monday: '03:00',
            tuesday: '03:00',
            wednesday: '03:00',
            thursday: '12:00',
            friday: '12:00',
            saturday: '12:00'
        },
        closingHours: {
            sunday: '23:59',
            monday: '23:59',
            tuesday: '23:59',
            wednesday: '23:59',
            thursday: '23:59',
            friday: '17:00',
            saturday: '17:00'
        },
        openPastMidnight: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: '02:00',
            saturday: '02:00'
        },
    },
    {
        id: 47,
        name: 'Big Time Entertainment',
        category: 'Entertainment',
        subcategory: 'All Ages',
        picturePath: '/images/bigtime.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1649519940/businessPictures/BigTimeEntertainment/bte1_fffnuq.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1649519941/businessPictures/BigTimeEntertainment/bte2_da6tgp.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1649519941/businessPictures/BigTimeEntertainment/bte3_yhe5xw.jpg',
        description: 'Big Time Entertainment features Southwestern Ontario\'s largest Gaming Arcade Centre! Electrified Go-Karts! 18 Holes of Glo in the Dark Mini Golf, Axe Throwing, Virtual Reality, Laser Tag, Pool Tables and Fowling!',
        phoneNumber: '519-969-5500',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: 'https://big-time.ca',
        facebookURL: 'https://www.facebook.com/BTEWindsor/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/btewindsor/',
        instagramUsername: 'btewindsor',
        instagramURL2: '',
        instagramUsername2: '',
        address: '2411 Dougall Ave, Windsor, ON N8X 1T3',
        googleMapsLink: 'https://goo.gl/maps/5ToJmak9EagD492R7',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11806.062090336114!2d-83.0254799!3d42.2888642!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1772f48d90c2acc2!2sBig%20Time%20Entertainment!5e0!3m2!1sen!2sca!4v1649519459736!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Wednesday - Thursday:',
            second: 'Friday:',
            third: 'Saturday:',
            fourth: 'Sunday',
            fifth: 'Monday - Tuesday'
        },
        hoursTimes: {
            first: '4:00 to 10:00pm',
            second: '3:00 to 11:00pm',
            third: '11:00am to 11:00pm',
            fourth: '11:00am to 9:00pm',
            fifth: 'CLOSED'
        },
        openingHours: {
            sunday: '11:00',
            monday: 'Closed',
            tuesday: 'Closed',
            wednesday: '16:00',
            thursday: '16:00',
            friday: '15:00',
            saturday: '11:00'
        },
        closingHours: {
            sunday: '21:00',
            monday: 'Closed',
            tuesday: 'Closed',
            wednesday: '22:00',
            thursday: '22:00',
            friday: '23:00',
            saturday: '23:00'
        },
        openPastMidnight: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        },
    },
    {
        id: 46,
        name: 'Simon\'s Prime Hamburgers',
        category: 'Restaurant',
        subcategory: 'Burgers',
        picturePath: '/images/simonsprime.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1647209269/businessPictures/SimonsPrime/sp1_agj9aw.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1647209269/businessPictures/SimonsPrime/sp2_y9hj6g.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1647209269/businessPictures/SimonsPrime/sp3_oz5hrx.jpg',
        description: 'Every bun is hand made daily by maple city bakery with no preserves. All meat is purchased through VG meats and delivered fresh never frozen!',
        phoneNumber: '519-915-1397',
        phoneNumberDescription: 'Tecumseh Rd',
        location1Name: 'Tecumseh Rd',
        phoneNumber2: '',
        phoneNumberDescription2: 'Ouellette Ave',
        location2Name: 'Ouellette Ave',
        publicEmail: '',
        websiteURL: 'http://simonsprimehamburgers.com',
        facebookURL: 'https://www.facebook.com/primefireplus/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/simonsprime/',
        instagramUsername: 'simonsprime',
        instagramURL2: '',
        instagramUsername2: '',
        address: '3580 Tecumseh Rd E, Windsor, ON N8W 1H6',
        googleMapsLink: 'https://goo.gl/maps/VHL74N8wBJCSf9zo7',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.7480468435238!2d-82.98316928450207!3d42.30524124655426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2b7042598351%3A0x28edc507b79ad8a!2s3580%20Tecumseh%20Rd%20E%2C%20Windsor%2C%20ON%20N8W%201H6!5e0!3m2!1sen!2sca!4v1647209208541!5m2!1sen!2sca',
        address2: '256 Ouellette Ave, Windsor, ON N9A 1A5',
        googleMapsLink2: 'https://goo.gl/maps/Hep3hxTU37M76Ga28',
        embeddedMapsLink2: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.1524221621307!2d-83.04116878450172!3d42.31794764575261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2d1f00549e9f%3A0x4a93375f0fdcca80!2s256%20Ouellette%20Ave%2C%20Windsor%2C%20ON%20N9A%201A5!5e0!3m2!1sen!2sca!4v1647209362847!5m2!1sen!2sca',
        hasHours: true,
        hasMultipleLocations: true,
        locationsHaveDifferentHours: true,
        hoursMessage: 'Dine-in, also available on UberEats, DoorDash and SkipTheDishes.',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Saturday:'
        },
        hoursTimes: {
            first: '12:00pm to 7:30pm',
            second: '11:00am to 8:30pm'
        },
        hoursDaysLocation2: {
            first: 'Sunday:',
            second: 'Monday - Wednesday:',
            third: 'Thursday:',
            fourth: 'Friday:',
            fifth: 'Saturday:'
        },
        hoursTimesLocation2: {
            first: '12:00pm to 10:00pm',
            second: '11:00am to 7:15pm',
            third: '11:00am to 10:00pm',
            fourth: '11:00am to 10:30pm',
            fifth: '12:00pm to 10:30pm'
        },
        openingHours: {
            sunday: '12:00',
            monday: '11:00',
            tuesday: '11:00',
            wednesday: '11:00',
            thursday: '11:00',
            friday: '11:00',
            saturday: '12:00'
        },
        closingHours: {
            sunday: '22:00',
            monday: '19:15',
            tuesday: '19:15',
            wednesday: '19:15',
            thursday: '22:00',
            friday: '22:30',
            saturday: '22:30'
        },
        openPastMidnight: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        },
    },
    {
        id: 45,
        name: 'More Taters Please',
        category: 'Restaurant',
        subcategory: 'Fast Food',
        picturePath: '/images/moretatersplease.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1647208441/businessPictures/MoreTatersPlease/mtp1_d9v9he.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1647208442/businessPictures/MoreTatersPlease/mtp2_fmtjkq.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1647208442/businessPictures/MoreTatersPlease/mtp3_bide7b.jpg',
        description: 'More Taters Please offers delicious comfort food with healthy choices.',
        phoneNumber: '519-419-5557',
        phoneNumberDescription: '',
        location1Name: 'Walker',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '*NEW Tecumseh Rd',
        publicEmail: '',
        websiteURL: 'https://moretatersplease.com',
        facebookURL: 'https://www.facebook.com/tatersplease/',
        etsyURL: '',
        instagramURL: 'https://instagram.com/moretatersplease?fbclid=IwAR2CrTY2WaW9LOHfHmDGKOGo0nlGPQReySiUnUkKTOwztae-HMPKdgyIKts',
        instagramUsername: 'moretatersplease',
        instagramURL2: '',
        instagramUsername2: '',
        address: '4110 Walker Rd, Windsor, ON N8W 3T5',
        googleMapsLink: 'https://goo.gl/maps/dQTYaXtht68vfN356',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11811.31627345283!2d-82.9670393!3d42.2608227!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xab7e1ee63c1e3c10!2sMore%20Taters%20Please!5e0!3m2!1sen!2sca!4v1647207927885!5m2!1sen!2sca',
        address2: '4320 Tecumseh Rd E, Windsor, ON N8W 1K3',
        googleMapsLink2: 'https://goo.gl/maps/QtsGMFR7r22i9isK6',
        embeddedMapsLink2: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.669997259531!2d-82.97426158450197!3d42.3069064464492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2b4c792a0033%3A0x8dfd2d2c21d241b2!2sMore%20Taters%20Please!5e0!3m2!1sen!2sca!4v1647208015233!5m2!1sen!2sca',
        hasHours: true,
        hasMultipleLocations: true,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Dine-in, also available on UberEats.',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Saturday:'
        },
        hoursTimes: {
            first: '11:00am to 10:00pm',
            second: '11:00am to Midnight'
        },
        openingHours: {
            sunday: '11:00',
            monday: '11:00',
            tuesday: '11:00',
            wednesday: '11:00',
            thursday: '11:00',
            friday: '11:00',
            saturday: '11:00'
        },
        closingHours: {
            sunday: '22:59',
            monday: '23:59',
            tuesday: '23:59',
            wednesday: '23:59',
            thursday: '23:59',
            friday: '23:59',
            saturday: '23:59'
        },
    },
    {
        id: 44,
        name: 'Ortona 1864 Café & Panino Shop Ltd.',
        category: 'Restaurant',
        subcategory: 'Cafe',
        picturePath: '/images/ortona.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1647207524/businessPictures/Ortona/ortona1_c7g6le.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1647207524/businessPictures/Ortona/ortona2_lc9nqi.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1647207524/businessPictures/Ortona/ortona3_lgqxc2.jpg',
        description: 'Italian style sandwich shop with espresso and full bar service.',
        phoneNumber: '226-674-0500',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: '',
        facebookURL: 'https://www.facebook.com/Ortona-1864-Café-Panino-Shop-Ltd-103299601080272/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/ortona1864/',
        instagramUsername: 'ortona1864',
        instagramURL2: '',
        instagramUsername2: '',
        address: '1864 Wyandotte St E, Windsor, ON N8Y 1E3',
        googleMapsLink: 'https://goo.gl/maps/FKvbq8nP9UmmhqTq9',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11799.725431441084!2d-83.0136041!3d42.3226628!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9388315032bf41e!2sOrtona%201864%20Cafe%20%26%20Panino!5e0!3m2!1sen!2sca!4v1647207006997!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Dine-in, also available on UberEats.',
        hoursDays: {
            first: 'Everyday:'
        },
        hoursTimes: {
            first: '11:00am to 11:00pm'
        },
        openingHours: {
            sunday: '11:00',
            monday: '11:00',
            tuesday: '11:00',
            wednesday: '11:00',
            thursday: '11:00',
            friday: '11:00',
            saturday: '11:00'
        },
        closingHours: {
            sunday: '23:00',
            monday: '23:00',
            tuesday: '23:00',
            wednesday: '23:00',
            thursday: '23:00',
            friday: '23:00',
            saturday: '23:00'
        },
    },
    {
        id: 43,
        name: 'Hip Hop Nails',
        category: 'Health & Beauty',
        subcategory: 'Nail Salon',
        picturePath: '/images/hiphopnails.jpg',
        description: 'We provide professional nail care service, and facial & waxing for Ladies and Gentlemen.',
        phoneNumber: '519-969-1143',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: '',
        facebookURL: '',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: '3202 Dougall Ave, Windsor, ON N9E 1S6',
        googleMapsLink: 'https://goo.gl/maps/uXsatrya5ZNg94sC8',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2952.4425636162587!2d-83.01189748450301!3d42.26907534883504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2c19c450a155%3A0xd0c12d9d73b37c91!2sHip%20Hop%20Nails!5e0!3m2!1sen!2sca!4v1645072672771!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Tuesday:',
            third: 'Wednesday - Friday:',
            fourth: 'Saturday'
        },
        hoursTimes: {
            first: '10:00am to 5:00pm',
            second: '10:00am to 7:00pm',
            third: '9:30am to 7:00pm',
            fourth: '9:30am to 6:00pm'
        },
        openingHours: {
            sunday: '10:00',
            monday: '10:00',
            tuesday: '10:00',
            wednesday: '09:30',
            thursday: '09:30',
            friday: '09:30',
            saturday: '09:30'
        },
        closingHours: {
            sunday: '17:00',
            monday: '19:00',
            tuesday: '19:00',
            wednesday: '19:00',
            thursday: '19:00',
            friday: '19:00',
            saturday: '16:00'
        },
    },
    {
        id: 42,
        name: 'Golden Flamez Candles',
        category: 'Shopping',
        subcategory: 'Candles',
        picturePath: '/images/goldenflamezcandles.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644941723/businessPictures/GoldenFlamezCandles/gfc1_smbbwt.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644941723/businessPictures/GoldenFlamezCandles/gfc2_xsg2zu.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644941723/businessPictures/GoldenFlamezCandles/gfc3_saxe5b.jpg',
        description: 'Soy Vegan Candles, Made with Organic Oils. Hand poured in small batches. Cruelty Free.',
        phoneNumber: '519-890-7701',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'goldenflamezcandles@gmail.com',
        websiteURL: 'https://www.goldenflamezcandles.com',
        facebookURL: 'https://www.facebook.com/goldenflamezcandles',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/goldenflamezcandles/',
        instagramUsername: 'goldenflamezcandles',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Harrow, ON',
        googleMapsLink: '',
        embeddedMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'These hours are for pick-up times only. Please order through our website first.',
        hoursDays: {
            first: 'Monday - Friday:',
            second: 'Saturday & Sunday:'
        },
        hoursTimes: {
            first: '10:00am to 6:00pm',
            second: '10:00am to 8:00pm'
        },
        openingHours: {
            sunday: '10:00',
            monday: '10:00',
            tuesday: '10:00',
            wednesday: '10:00',
            thursday: '10:00',
            friday: '10:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: '20:00',
            monday: '18:00',
            tuesday: '18:00',
            wednesday: '18:00',
            thursday: '18:00',
            friday: '18:00',
            saturday: '20:00'
        },
    },
    {
        id: 41,
        name: 'Mi Casita',
        category: 'Restaurant',
        subcategory: 'Mexican',
        picturePath: '/images/micasita.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644280831/businessPictures/MiCasita/micasita1_ennvb2.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644337738/businessPictures/MiCasita/micasita2_xvggqe.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644338022/businessPictures/MiCasita/micasita3_fsmzey.jpg',
        description: 'A local family owned restaurant serving fresh made to order Salvadoran and Mexican cuisine. Open for dine in or take out (order online).',
        phoneNumber: '519-253-2274',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'micasitawindsor@yahoo.ca',
        websiteURL: 'https://www.micasitawindsor.com/foodticket/',
        facebookURL: 'https://www.facebook.com/micasitawindsor/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/micasitawindsor/',
        instagramUsername: 'micasitawindsor',
        instagramURL2: '',
        instagramUsername2: '',
        address: '429 Wyandotte St E, Windsor, ON N9A 3H8',
        googleMapsLink: 'https://goo.gl/maps/zT4BTBir9SmqHjJV7',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11800.943414025867!2d-83.03067!3d42.316168!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5b394f1a8629199a!2sMi%20Casita%20Restaurant%20%26%20Bar!5e0!3m2!1sen!2sca!4v1644175031221!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday:',
            third: 'Tuesday - Thursday:',
            fourth: 'Friday',
            fifth: 'Saturday'
        },
        hoursTimes: {
            first: '2:00pm to 9:00pm',
            second: 'CLOSED',
            third: '12:00pm to 9:00pm',
            fourth: '12:00pm to 11:00pm',
            fifth: '2:00pm to 11:00pm'
        },
        openingHours: {
            sunday: '14:00',
            monday: 'Closed',
            tuesday: '12:00',
            wednesday: '12:00',
            thursday: '12:00',
            friday: '12:00',
            saturday: '14:00'
        },
        closingHours: {
            sunday: '21:00',
            monday: 'Closed',
            tuesday: '21:00',
            wednesday: '21:00',
            thursday: '21:00',
            friday: '23:00',
            saturday: '23:00'
        },
        openPastMidnight: {
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
        }
    },
    {
        id: 40,
        name: 'Cafe Amor & Art',
        category: 'Restaurant',
        subcategory: 'Cafe',
        picturePath: '/images/cafeamor.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644338996/businessPictures/CafeAmorandArt/CafeAmor1_ajn1k5.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644339131/businessPictures/CafeAmorandArt/CafeAmor2_efqo4e.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644339137/businessPictures/CafeAmorandArt/CafeAmor3_xvgeg2.jpg',
        description: 'We promote Latin American art and culture through great specialty coffee, fresh food, books, paintings, artisanal pottery, and handcrafted jewelry.',
        phoneNumber: '519-999-8185',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'contact@cafeamor.ca',
        websiteURL: 'http://cafeamor.ca',
        facebookURL: 'https://www.facebook.com/CafeAmorArt/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/cafe_amor_art/',
        instagramUsername: 'cafe_amor_art',
        instagramURL2: '',
        instagramUsername2: '',
        address: '1557 Ottawa St, Windsor, ON N8X 2G3',
        googleMapsLink: 'https://goo.gl/maps/xsUhrNkTdSzYu5dW9',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.4892062526137!2d-83.011584784502!3d42.31076344620572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2d2a5bb3811d%3A0x5d57101f4565e254!2sCaf%C3%A9%20Amor%20%26%20Art!5e0!3m2!1sen!2sca!4v1643833851438!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday:',
            third: 'Tuesday - Thursday:',
            fourth: 'Friday',
            fifth: 'Saturday'
        },
        hoursTimes: {
            first: '9:00am to 5:00pm',
            second: 'CLOSED',
            third: '8:00am to 5:00pm',
            fourth: '8:00am to 6:00pm',
            fifth: '9:00am to 6:00pm'
        },
        openingHours: {
            sunday: '09:00',
            monday: 'Closed',
            tuesday: '08:00',
            wednesday: '08:00',
            thursday: '08:00',
            friday: '08:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: 'Closed',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '18:00',
            saturday: '18:00'
        },
    },
    {
        id: 39,
        name: 'Mexico Lindo',
        category: 'Food',
        subcategory: 'Grocery Store',
        picturePath: '/images/mexicolindo.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644339391/businessPictures/MexicoLindo/MexicoLindo1_wdmpeb.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644339475/businessPictures/MexicoLindo/MexicoLindo2_f4fri9.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644339496/businessPictures/MexicoLindo/MexicoLindo3_ybl1q6.jpg',
        description: 'Mexican & Latin American Groceries. Catering available. Crafts, Piñatas, Take-Out Food Daily.',
        phoneNumber: '519-988-1151',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: '',
        facebookURL: 'https://www.facebook.com/Mexico-Lindo-259112180837681/',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: '4611 Tecumseh Road East, Windsor, ON N8W 1K8',
        googleMapsLink: 'https://goo.gl/maps/M9UpB2Wss1jvW6gA6',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11802.645031360094!2d-82.9688305!3d42.3070929!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x35b97f3e70b56561!2sMexico%20Lindo%20Latin%20American%20Food%20Market!5e0!3m2!1sen!2sca!4v1643833805920!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday:',
            third: 'Tuesday - Saturday:'
        },
        hoursTimes: {
            first: '11:00am to 5:00pm',
            second: '11:00am to 6:00pm',
            third: '10:00am to 6:00pm'
        },
        openingHours: {
            sunday: '11:00',
            monday: '11:00',
            tuesday: '10:00',
            wednesday: '10:00',
            thursday: '10:00',
            friday: '10:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '18:00',
            tuesday: '18:00',
            wednesday: '18:00',
            thursday: '18:00',
            friday: '18:00',
            saturday: '18:00'
        },
    },
    {
        id: 38,
        name: 'DiamondTradez',
        category: 'Contractors',
        subcategory: 'Finance and Investing',
        picturePath: '/images/diamondtradez.png',
        description: '',
        phoneNumber: '',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'diamondtradez@outlook.com',
        websiteURL: 'https://www.diamondtradez.net',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/diamondtradez/',
        instagramUsername: 'diamondtradez',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        embeddedMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: false,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 37,
        name: 'Clarice Candace Photography',
        category: 'Events',
        subcategory: 'Photography',
        picturePath: '/images/claricephotography.jpg',
        description: '',
        phoneNumber: '',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'ccphotography2021@hotmail.com',
        websiteURL: '',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/claricecandacephotography/',
        instagramUsername: 'claricecandacephotography',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        embeddedMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Please call or message to book an appointment.',
        hoursDays: {},
        hoursTimes: {},
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 36,
        name: 'Maverick Woodworks',
        category: 'Shopping',
        subcategory: 'Furniture',
        picturePath: '/images/maverickwoodworks.jpeg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644339756/businessPictures/MaverickWoodworks/MaverickWoodworks1_bkkrqs.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644339863/businessPictures/MaverickWoodworks/MaverickWoodworks2_argef5.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644339863/businessPictures/MaverickWoodworks/MaverickWoodworks3_ampxcf.jpg',
        description: '',
        phoneNumber: '519-560-7722',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'maverick_woodworks@outlook.com',
        websiteURL: '',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/maverick_woodworks/',
        instagramUsername: 'maverick_woodworks',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        embeddedMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Please call or message to book an appointment.',
        hoursDays: {},
        hoursTimes: {},
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 35,
        name: 'Flowers by Toni',
        category: 'Shopping',
        subcategory: 'Floral Design',
        picturePath: '/images/flowersbytoni.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644339994/businessPictures/FlowersByToni/FlowersByToni1_clnd2l.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644339994/businessPictures/FlowersByToni/FlowersByToni2_wvhnpg.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644339994/businessPictures/FlowersByToni/FlowersByToni3_treabg.jpg',
        description: 'Fresh flowers for all occasions! Email, call, or message on Facebook to place your order today! $65 for a dozen roses. Please contact for other prices.',
        phoneNumber: '519-551-6405',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: ' amontaleone@hotmail.ca',
        websiteURL: '',
        facebookURL: 'https://www.facebook.com/Flowers-By-Toni-248130792583105',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        embeddedMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Please call or email to order.',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Saturday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '9:00pm to 5:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: 'Closed',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 34,
        name: 'Green Envy',
        category: 'Shopping',
        subcategory: 'Eco-Friendly',
        picturePath: '/images/greenenvy.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1646068936/businessPictures/GreenEnvy/greenenvy1_hsdovv.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1646068904/businessPictures/GreenEnvy/greenenvy2_pkok7a.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1646068904/businessPictures/GreenEnvy/greenenvy3_lotedi.jpg',
        description: 'Your local Cruelty-Free/ Eco-Friendly/ Refill Shop located in Olde Riverside! Reduce your waste with us!',
        phoneNumber: '519-915-0830',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'greenenvywindsoressex@gmail.com',
        websiteURL: 'https://www.greenenvywindsor.com',
        facebookURL: 'https://www.facebook.com/greenenvywindsoressex/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/greenenvywindsoressex',
        instagramUsername: 'greenenvywindsoressex',
        instagramURL2: '',
        instagramUsername2: '',
        address: '5530 Wyandotte St E, Windsor, ON N8S 1M1',
        googleMapsLink: 'https://goo.gl/maps/cu6UHfYXDQzin9s59',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11798.641425462927!2d-82.9699703!3d42.3284425!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaaed09e273601741!2sGreen%20Envy!5e0!3m2!1sen!2sca!4v1643738217975!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Sunday and Monday:',
            second: 'Tuesday and Thursday:',
            third: 'Wednesday and Friday:',
            fourth: 'Saturday'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '11:00am to 6:00pm',
            third: '10:00am to 5:00pm',
            fourth: '10:00am to 2:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: '11:00',
            wednesday: '10:00',
            thursday: '11:00',
            friday: '10:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: '18:00',
            wednesday: '17:00',
            thursday: '18:00',
            friday: '17:00',
            saturday: '14:00'
        },
    },
    {
        id: 33,
        name: 'The Woodslee Ranch',
        category: 'Events',
        subcategory: 'Airbnb',
        picturePath: '/images/woodsleeranch.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644340683/businessPictures/WoodsleeRanch/Woodslee1_vtr778.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644340683/businessPictures/WoodsleeRanch/Woodslee2_krxcja.webp',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644340683/businessPictures/WoodsleeRanch/Woodslee3_xx2bk0.webp',
        description: 'The Woodslee Ranch is an Air Bnb that backs onto the Belle River and twenty acres of woods in the heart of Essex County. The house was custom built in 2019 with close attention to detail and design. With old hemlock barn beams that were restored from an old family barn, the ranch has a modern look with a rustic chic appeal. The space has three bedrooms and two full baths that sleeps up to six comfortably. Whether you want to entertain under the newly built 20x20 gazebo, relax in the outdoor wood burning sauna or sit and enjoy the hot tub under the stars, The Woodslee ranch is the perfect spot to unwind, relax and refresh. If your looking to book a wedding, a bridal party, girls weekend or a couples retreat we have special packages for all types of events. We also provide a personal chef, private bartenders, and registered massage therapist while you and your guest can relax and enjoy the many amenities we have to offer. The Woodslee ranch is close to many breweries, and approximately fifteen minutes away from the twenty plus wineries along the North shore of Lake Erie.',
        phoneNumber: '519-919-0845',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'ikristian@hotmail.com',
        websiteURL: 'https://www.airbnb.ca/rooms/36529549?adults=2&s=42&unique_share_id=74F7781B-4CA4-421B-802A-6831296A1208&_branch_match_id=1017079836094478113&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXT0zKS9LLTdUPKbMsiCoIysvMSwIAUFDsmxsAAAA%3D',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/the.woodslee.ranch/',
        instagramUsername: 'the.woodslee.ranch',
        instagramURL2: '',
        instagramUsername2: '',
        address: '1697 County Rd 46, South Woodslee, ON N0R 1V0',
        googleMapsLink: 'https://goo.gl/maps/WMsnfJhrKnrQFSt28',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2954.993398300264!2d-82.71983703811249!3d42.21458573141508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883ace3bcac48afd%3A0xf858d10b9a8970db!2s1697%20Essex%20County%20Rd%2046%2C%20South%20Woodslee%2C%20ON%20N0R%201V0!5e0!3m2!1sen!2sca!4v1643729428289!5m2!1sen!2sca',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'If you and your group are interested in booking an event or your next getaway please contact us on our air bnb link above or you follow and message us on Instagram.',
        hoursDays: {},
        hoursTimes: {},
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 32,
        name: 'Bartini - Mobile Bar',
        category: 'Events',
        subcategory: 'Mobile Bar',
        picturePath: '/images/bartini.jpeg',
        description: ' "The Party Within The Party"® Bartending service for any type of event, big or small! We provide everything needed to make the bar/drinks the best part of the evening! ',
        phoneNumber: '',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'bartini@hotmail.ca',
        websiteURL: 'https://www.bartini.ca',
        facebookURL: 'https://www.facebook.com/bartiniwindsor',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/bartiniwindsor/',
        instagramUsername: 'bartiniwindsor',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        embeddedMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Please visit our website or email us to book a reservation.',
        hoursDays: {},
        hoursTimes: {},
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 31,
        name: 'HH Home Renovations',
        category: 'Contractors',
        subcategory: 'Home Renovations',
        picturePath: '/images/defaultpicture.jpg',
        description: '',
        phoneNumber: '226-246-4984',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: '',
        facebookURL: '',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        embeddedMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Please text or call the number above for inquiries.',
        hoursDays: {},
        hoursTimes: {},
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 30,
        name: 'The We Store',
        category: 'Shopping',
        subcategory: 'Cannabis',
        picturePath: '/images/westore.png',
        description: '',
        phoneNumber: '226-773-0992',
        phoneNumberDescription: '(Tecumseh Rd)',
        location1Name: 'Windsor (Tecumseh Rd)',
        phoneNumber2: '226-783-6259',
        phoneNumberDescription2: '(Huron Church)',
        location2Name: 'Windsor (Huron Church)',
        publicEmail: 'info@thewestore.com',
        websiteURL: 'https://thewestore.com',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/westorecdn/',
        instagramUsername: 'westorecdn',
        instagramURL2: '',
        instagramUsername2: '',
        address: '6050 Tecumseh Rd E, Windsor, ON N8T 1E3',
        googleMapsLink: 'https://goo.gl/maps/zVKwnUFbhYhAHPKw9',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.4914375116746!2d-82.95501208450193!3d42.31071584620888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2b1e62a7c125%3A0x5158dd3653c1dffe!2s6050%20Tecumseh%20Rd%20E%2C%20Windsor%2C%20ON%20N8T%201E3!5e0!3m2!1sen!2sca!4v1643669517663!5m2!1sen!2sca',
        address2: '1800 Huron Church Rd, Windsor, ON N9C 2L5',
        googleMapsLink2: 'https://goo.gl/maps/UfZZHWtshQZUyNY19',
        embeddedMapsLink2: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2951.7596067023765!2d-83.05709808450263!3d42.28365464791582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2dbfa76eb9c1%3A0x2ea726f29a3af1cf!2s1800%20Huron%20Church%20Rd%2C%20Windsor%2C%20ON%20N9C%202L5!5e0!3m2!1sen!2sca!4v1643669563854!5m2!1sen!2sca',
        hasHours: true,
        hasMultipleLocations: true,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Two other locations with the same hours located at 4050 Walker Road, Unit 300 and 1565 Wyandotte St. E.',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Saturday:'

        },
        hoursTimes: {
            first: '10:00am to 10:00pm',
            second: '9:00am to 11:00pm'
        },
        openingHours: {
            sunday: '10:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '22:00',
            monday: '23:00',
            tuesday: '23:00',
            wednesday: '23:00',
            thursday: '23:00',
            friday: '23:00',
            saturday: '23:00'
        },
    },
    {
        id: 29,
        name: 'Lady Horse Clothing',
        category: 'Shopping',
        subcategory: 'Vintage Clothing',
        picturePath: '/images/ladyhorseclothing.jpeg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644341289/businessPictures/LadyHorse/LadyHorse1_qpcous.webp',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644341289/businessPictures/LadyHorse/LadyHorse2_zyxsh2.webp',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644341289/businessPictures/LadyHorse/LadyHorse3_jbdgnu.jpg',
        description: 'Ladyhorse is a specially curated selection of the best vintage clothing. We choose from hundreds and hundreds of items to find the most hardy and beautiful vintage. Finding beauty in secondhand pieces is what we do.',
        phoneNumber: '647-890-2468',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'o.a.nelson@outlook.com',
        websiteURL: 'https://ladyhorse.ca',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/ladyhorseclothing/',
        instagramUsername: 'ladyhorseclothing',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Order online at any time using the website link above. Message on Instagram for drop-off/pick-up times.',
        hoursDays: {},
        hoursTimes: {},
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 28,
        name: 'Pipe\'s Palace',
        category: 'Shopping',
        subcategory: 'Smoke Shop',
        picturePath: '/images/pipespalace.jpeg',
        description: '',
        phoneNumber: '519-988-0420',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'pipespalace@gmail.com',
        websiteURL: '',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/pipespalacewindsor/',
        instagramUsername: 'pipespalacewindsor',
        instagramURL2: '',
        instagramUsername2: '',
        address: '6058 Tecumseh Rd E, Windsor, ON N8T 1E3',
        googleMapsLink: 'https://goo.gl/maps/B4dMDnAGtTFUW8oq9',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.494676583717!2d-82.95482298450194!3d42.31064674621321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2b1e7b59abfd%3A0xc527d09cb77f4e0f!2s6058%20Tecumseh%20Rd%20E%2C%20Windsor%2C%20ON%20N8T%201E3!5e0!3m2!1sen!2sca!4v1643658883907!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Saturday:'
        },
        hoursTimes: {
            first: '12:00pm to 3:00pm',
            second: '12:00pm to 8:00pm'
        },
        openingHours: {
            sunday: '12:00',
            monday: '12:00',
            tuesday: '12:00',
            wednesday: '12:00',
            thursday: '12:00',
            friday: '12:00',
            saturday: '12:00'
        },
        closingHours: {
            sunday: '15:00',
            monday: '20:00',
            tuesday: '20:00',
            wednesday: '20:00',
            thursday: '20:00',
            friday: '20:00',
            saturday: '20:00'
        },
    },
    {
        id: 27,
        name: 'Armando\'s Carpet & Tile Cleaning',
        category: 'Contractors',
        subcategory: 'Cleaning Service',
        picturePath: '/images/armandoscleaning.jpg',
        description: 'Fast, efficient, and honest, Armando\'s Carpet, Tile & Upholstery Cleaning has become a reputable and well-known Carpet Cleaning Service. Our team is up for every job, managing projects with the skill and experience our clients have come to expect.',
        phoneNumber: '519-979-6953',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: 'https://www.armandoscarpetcleaningwindsor.com',
        facebookURL: '',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: '',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Please go to our website to get a quote online.',
        hoursDays: {
            first: 'Every day of the week:'
        },
        hoursTimes: {
            first: '8:00am to 7:00pm'
        },
        openingHours: {
            sunday: '08:00',
            monday: '08:00',
            tuesday: '08:00',
            wednesday: '08:00',
            thursday: '08:00',
            friday: '08:00',
            saturday: '08:00'
        },
        closingHours: {
            sunday: '19:00',
            monday: '19:00',
            tuesday: '19:00',
            wednesday: '19:00',
            thursday: '19:00',
            friday: '19:00',
            saturday: '19:00'
        },
    },
    {
        id: 26,
        name: 'The Green Clean Team',
        category: 'Contractors',
        subcategory: 'Cleaning Service',
        picturePath: '/images/greencleanteam.png',
        description: 'Green Clean Team is changing the way you clean. Being the first of its kind in Windsor-Essex County, we provide eco friendly cleaning for whatever the situation may be using Stabilized Aqueous Ozone (SAO) technology, to suit any and all cleaning needs!',
        phoneNumber: '519-982-9331',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'adrian@thegclean.com',
        websiteURL: '',
        facebookURL: 'https://www.facebook.com/GCTCleaning/',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: '1501 Howard Ave, Windsor, ON N8X 3T5',
        googleMapsLink: 'https://goo.gl/maps/EAh819awbxPXEDoq9',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11803.473241778609!2d-83.0207799!3d42.3026753!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x180a3a439db30572!2sThe%20Green%20Clean%20Team!5e0!3m2!1sen!2sca!4v1643648172305!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Please email or call to book an appointment.',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Friday:',
            third: 'Saturday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '12:00pm to 8:00pm',
            third: '10:00am to 4:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: '12:00',
            tuesday: '12:00',
            wednesday: '12:00',
            thursday: '12:00',
            friday: '12:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: 'Closed',
            monday: '20:00',
            tuesday: '20:00',
            wednesday: '20:00',
            thursday: '20:00',
            friday: '20:00',
            saturday: '16:00'
        },
    },
    {
        id: 25,
        name: 'G.S.S. Towing',
        category: 'Automotive',
        subcategory: 'Mechanic',
        picturePath: '/images/defaultpicture.jpg',
        description: 'Oil change services & lube. Tire services and repair.',
        phoneNumber: '416-991-9090',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: '',
        facebookURL: '',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: '2130 South Pacific St #3 Windsor, ON N8X 2A3',
        googleMapsLink: 'https://goo.gl/maps/UcNLQqhKAMDuh2M2A',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2951.3051815248923!2d-83.03400468450232!3d42.29335314730425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2c53c8a210cd%3A0x2a26718331333b9a!2s2130%20S%20Pacific%20St%20%233%2C%20Windsor%2C%20ON%20N8X%202A3!5e0!3m2!1sen!2sca!4v1643610121212!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Please call or text to book an appointment.',
        hoursDays: {},
        hoursTimes: {},
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 24,
        name: 'Anchor Coffee House',
        category: 'Food',
        subcategory: 'Coffee Shop',
        picturePath: '/images/anchor.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644341451/businessPictures/Anchor/Anchor1_brdthm.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644341451/businessPictures/Anchor/Anchor2_pswfig.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644341451/businessPictures/Anchor/Anchor3_aapwgw.jpg',
        description: 'Anchor is a locally owned and operated cafe and bakery. We\'re proud to carry a variety of Canadian coffee roasters and bake everything in house. We have a limited breakfast and lunch menu and also offer catering and home delivery.',
        phoneNumber: '519-915-0626',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'info@anchorcoffeehouse.com',
        websiteURL: 'https://www.anchorcoffeehouse.com',
        facebookURL: 'https://www.facebook.com/Anchor-Coffee-House-176585555847820',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/anchorcoffeehouse/?hl=en',
        instagramUsername: 'anchorcoffeehouse',
        instagramURL2: '',
        instagramUsername2: '',
        address: '543 Lincoln Rd, Windsor, ON N8Y 2G6',
        googleMapsLink: 'https://goo.gl/maps/SNsFCCJ7CSLJ7J4D6',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.9579914671044!2d-83.01854358450156!3d42.322094745490865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2cc0ee648997%3A0x653df15f48bfce2e!2sAnchor%20Coffee%20House!5e0!3m2!1sen!2sca!4v1643320725111!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: '',
        hoursDays: {
            first: 'Every day of the week:'
        },
        hoursTimes: {
            first: '9:00am to 3:00pm'
        },
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '15:00',
            monday: '15:00',
            tuesday: '15:00',
            wednesday: '15:00',
            thursday: '15:00',
            friday: '15:00',
            saturday: '15:00'
        },
    },
    {
        id: 23,
        name: 'Michael Difazio Reclaim Artistry',
        category: 'Shopping',
        subcategory: 'Furniture',
        picturePath: '/images/mikedifazio.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644341739/businessPictures/MichaelDifazio/Difazio1_ehzzct.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644341739/businessPictures/MichaelDifazio/Difazio2_xfo5sh.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644341739/businessPictures/MichaelDifazio/Difazio3_cogh0k.jpg',
        description: 'Custom made furniture tailor made to your home. For six years we have been specializing in reclaimed materials producing bespoke furniture for customers looking to make a statement. Visit us online or in-store to find out more.',
        phoneNumber: '519-890-5931',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'md.reclaimartistry@gmail.com',
        websiteURL: 'https://www.michaeldifazio.ca',
        facebookURL: 'https://www.facebook.com/michaeldifazioreclaim/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/michaeldifazio.reclaimartistry/?hl=en',
        instagramUsername: 'michaeldifazio.reclaimartistry',
        instagramURL2: '',
        instagramUsername2: '',
        address: '1023 Drouillard Rd, Windsor, ON N8Y 2P9',
        googleMapsLink: 'https://goo.gl/maps/EmPfm8TQWpJRXfsM7',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.993656927251!2d-83.00235078450166!3d42.321334045538926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2ca3bebd7e91%3A0xde098a34d3fffc20!2sMichael%20Difazio%20Reclaim%20Artistry!5e0!3m2!1sen!2sca!4v1643241794120!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Bookings are preferred through website link above.',
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Friday:',
            third: 'Saturday'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '8:00am to 5:00pm',
            third: '10:00am to 2:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: '08:00',
            tuesday: '08:00',
            wednesday: '08:00',
            thursday: '08:00',
            friday: '08:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: 'Closed',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '14:00'
        },
    },
    {
        id: 22,
        name: 'Rare Butcher',
        category: 'Food',
        subcategory: 'Butcher Shop',
        picturePath: '/images/rare.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644341873/businessPictures/RareButcher/Rare1_gjy5d4.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644341873/businessPictures/RareButcher/Rare2_du11oh.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644341873/businessPictures/RareButcher/Rare3_mzji1f.jpg',
        description: '',
        phoneNumber: '519-979-3675',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'rob@rarebutcher.ca',
        websiteURL: 'https://rarebutcher.ca',
        facebookURL: 'https://www.facebook.com/rarebutcher/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/rarebutcher/?hl=en',
        instagramUsername: 'rarebutcher',
        instagramURL2: '',
        instagramUsername2: '',
        address: '35 Amy Croft Dr Unit 4, Lakeshore, ON N9K 1C8',
        googleMapsLink: 'https://goo.gl/maps/tRVDsm33mj4iY6ED7',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.734632541589!2d-82.86699668450203!3d42.30552744653625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883ad54a496d659d%3A0xa961aa318353eb4c!2sRare%20Butcher%20Shop!5e0!3m2!1sen!2sca!4v1643237494995!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday:',
            third: 'Tuesday:',
            fourth: 'Wednesday - Friday',
            fifth: 'Saturday'
        },
        hoursTimes: {
            first: '10:00am to 5:00pm',
            second: '10:00am to 7:00pm',
            third: 'CLOSED',
            fourth: '10:00am to 7:00pm',
            fifth: '10:00am to 6:00pm'
        },
        openingHours: {
            sunday: '10:00',
            monday: '10:00',
            tuesday: 'Closed',
            wednesday: '10:00',
            thursday: '10:00',
            friday: '10:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '19:00',
            tuesday: 'Closed',
            wednesday: '19:00',
            thursday: '19:00',
            friday: '19:00',
            saturday: '18:00'
        },
    },
    {
        id: 21,
        name: 'Plant Joy',
        category: 'Food',
        subcategory: 'Donut Shop',
        picturePath: '/images/plantjoy.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644341997/businessPictures/PlantJoy/PlantJoy1_bhi6i7.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644341997/businessPictures/PlantJoy/PlantJoy2_zdxh9r.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644341997/businessPictures/PlantJoy/PlantJoy3_ek0cfs.jpg',
        description: 'Vegan doughnuts made with organic and fair-trade ingredients.',
        phoneNumber: '226-246-7196',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'hello@plantjoy.ca',
        websiteURL: 'https://plantjoy.ca',
        facebookURL: 'https://www.facebook.com/plantjoyyqg/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/plantjoyyqg/?hl=en',
        instagramUsername: 'plantjoyyqg',
        instagramURL2: '',
        instagramUsername2: '',
        address: '5622 Wyandotte St E, Windsor, ON N8S 1M1',
        googleMapsLink: 'https://goo.gl/maps/BHz5MCnyCRjYRWMH9',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.6524390210666!2d-82.97137958450143!3d42.32861134507962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2d0fff65950b%3A0xa93d301a01d032c6!2sPlant%20Joy!5e0!3m2!1sen!2sca!4v1643235038352!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday - Monday:',
            second: 'Tuesday - Friday:',
            third: 'Saturday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '11:00am to 6:00pm',
            third: '11:00am to 5:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: '11:00',
            wednesday: '11:00',
            thursday: '11:00',
            friday: '11:00',
            saturday: '11:00'
        },
        closingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: '18:00',
            wednesday: '18:00',
            thursday: '18:00',
            friday: '18:00',
            saturday: '17:00'
        },
    },
    {
        id: 20,
        name: 'Riverside Pie Cafe',
        category: 'Food',
        subcategory: 'Bakery',
        picturePath: '/images/rpc.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644342137/businessPictures/RPC/RPC1_drtkf2.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644342137/businessPictures/RPC/RPC2_gvyvpv.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644342137/businessPictures/RPC/RPC3_ehbuau.jpg',
        description: 'Riverside Pie Cafe features made from scratch sweet and savoury pies and pastries.',
        phoneNumber: '226-221-9117',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'olivia@riversidepiecafe.com',
        websiteURL: 'https://www.riversidepiecafe.com',
        facebookURL: 'https://www.facebook.com/riversidepiecafe/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/riversidepiecafe/?hl=en',
        instagramUsername: 'riversidepiecafe',
        instagramURL2: '',
        instagramUsername2: '',
        address: '5560 Wyandotte St E, Windsor, ON N8S 1M1',
        googleMapsLink: 'https://goo.gl/maps/enQBPvy96ASUiNLD9',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.6584082700124!2d-82.97191988450145!3d42.328484045087635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2b364d7ca71d%3A0x9462928c397245a8!2sRiverside%20Pie%20Cafe!5e0!3m2!1sen!2sca!4v1643228160597!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday - Tuesday:',
            second: 'Wednesday - Friday:',
            third: 'Saturday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '10:00am to 6:00pm',
            third: '10:00am to 4:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: 'Closed',
            wednesday: '10:00',
            thursday: '10:00',
            friday: '10:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: 'Closed',
            wednesday: '18:00',
            thursday: '18:00',
            friday: '18:00',
            saturday: '16:00'
        },
    },
    {
        id: 19,
        name: 'Walkerville Mercantile',
        category: 'Shopping',
        subcategory: 'Men\'s Clothing',
        picturePath: '/images/walkervillemercantile.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644342252/businessPictures/WalkervilleMercantile/WM1_tr6fcl.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644342252/businessPictures/WalkervilleMercantile/WM2_nespal.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644342252/businessPictures/WalkervilleMercantile/WM3_vwiegd.jpg',
        description: 'Affordable luxury men\'s clothing & accessories.',
        phoneNumber: '226-674-0118',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'info@walkervillemercantile.com',
        websiteURL: 'https://www.walkervillemercantile.com',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/walkervillemercantile/?hl=en',
        instagramUsername: 'walkervillemercantile',
        instagramURL2: '',
        instagramUsername2: '',
        address: '1719 Wyandotte St E, Windsor, ON N8Y 1C9',
        googleMapsLink: 'https://goo.gl/maps/cJ4eoNTdBtiBKWkG8',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.9634489210252!2d-83.01682358450164!3d42.32197834549811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2db980ca8e1b%3A0xa02be012f4b77be8!2sWalkerville%20Mercantile%20Ltd.!5e0!3m2!1sen!2sca!4v1643227823764!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Monday - Tuesday:',
            second: 'Wednesday - Saturday:',
            third: 'Sunday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '11:00am to 7:00pm',
            third: '11:00am to 4:00pm'
        },
        openingHours: {
            sunday: '11:00',
            monday: 'Closed',
            tuesday: 'Closed',
            wednesday: '11:00',
            thursday: '11:00',
            friday: '11:00',
            saturday: '11:00'
        },
        closingHours: {
            sunday: '16:00',
            monday: 'Closed',
            tuesday: 'Closed',
            wednesday: '19:00',
            thursday: '19:00',
            friday: '19:00',
            saturday: '19:00'
        },
    },
    {
        id: 18,
        name: 'The Doggy Bakery',
        category: 'Shopping',
        subcategory: 'Pet Supplies',
        picturePath: '/images/thedoggybakery.png',
        description: 'We have locally homemade dog cookies in different types and sizes. Basic cakes are also available in three sizes 7.5" bone, 6" round & 3" rectangle. Base coat icing is brown with choice of four colours for the righting: blue, pink white or purple. We also sell pet food from Canadian companies as well as sweaters, collars, toys, bedding, shampoo\'s and much more. We also carry Hemp4paws cbd oil.',
        phoneNumber: '519-962-7150',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'stacydrouillard@gmail.com',
        websiteURL: 'https://thedoggybakery.ca',
        facebookURL: 'https://www.facebook.com/The-Doggy-Bakery-218036678344824/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/thedoggybakery/',
        instagramUsername: 'thedoggybakery',
        instagramURL2: '',
        instagramUsername2: '',
        address: '571 Lincoln Rd, Windsor, ON N8Y 1C7',
        googleMapsLink: 'https://goo.gl/maps/obbpaTJpgAvNryid7',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.96646833187!2d-83.01839808450157!3d42.321913945502345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2cc08c96ef6b%3A0x21db1d001a1d939c!2sThe%20Doggy%20Bakery!5e0!3m2!1sen!2sca!4v1643226997913!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Monday - Tuesday:',
            second: 'Wednesday - Saturday:',
            third: 'Sunday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '10:00am to 5:00pm',
            third: '12:00pm to 4:00pm'
        },
        openingHours: {
            sunday: '12:00',
            monday: 'Closed',
            tuesday: 'Closed',
            wednesday: '10:00',
            thursday: '10:00',
            friday: '10:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: '16:00',
            monday: 'Closed',
            tuesday: 'Closed',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 17,
        name: 'Chance Coffee',
        category: 'Food',
        subcategory: 'Coffee Shop',
        picturePath: '/images/chancecoffee.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644342410/businessPictures/ChanceCoffee/Chance1_hvbbd5.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644342410/businessPictures/ChanceCoffee/Chance2_qnzbiq.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644342410/businessPictures/ChanceCoffee/Chance3_y7uz6f.jpg',
        description: '',
        phoneNumber: '',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'ryan@chancecoffee.ca',
        websiteURL: 'https://chancecoffee.ca',
        facebookURL: 'https://www.facebook.com/chancecoffeeroasting',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/chancecoffee/',
        instagramUsername: 'chancecoffee',
        instagramURL2: '',
        instagramUsername2: '',
        address: '1012 Drouillard Rd, Windsor, ON N8Y 2P8',
        googleMapsLink: 'https://goo.gl/maps/1NdcVQ46JBWCu9kL6',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.9791179349763!2d-83.0020872845016!3d42.32164414551906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2bf4b02acf2b%3A0xc5c206443cc00f34!2sChance%20Coffee!5e0!3m2!1sen!2sca!4v1643226429138!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday - Monday:',
            second: 'Tuesday:',
            third: 'Wednesday - Saturday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '8:30am to 1:00pm',
            third: '8:30am to 4:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: '08:30',
            wednesday: '08:30',
            thursday: '08:30',
            friday: '08:30',
            saturday: '08:30'
        },
        closingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: '13:00',
            wednesday: '16:00',
            thursday: '16:00',
            friday: '16:00',
            saturday: '16:00'
        },
    },
    {
        id: 16,
        name: 'Parks and Rec Gastropub',
        category: 'Bar',
        subcategory: 'Sports Bar',
        picturePath: '/images/parksandrec.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644342528/businessPictures/ParksandRec/PC1_b7qagi.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644342528/businessPictures/ParksandRec/PC2_rngjst.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644342528/businessPictures/ParksandRec/PC3_p9sz9k.jpg',
        description: '',
        phoneNumber: '519-956-8956',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: 'https://parksrec.ca/menu/',
        facebookURL: 'https://www.facebook.com/parksrecwindsor/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/parksrecwindsor/',
        instagramUsername: 'parksrecwindsor',
        instagramURL2: '',
        instagramUsername2: '',
        address: '3087 Forest Glade Dr, Windsor, ON N8R 1W6',
        googleMapsLink: 'https://goo.gl/maps/FxszPZgNqKY9Cdhb7',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.724939701773!2d-82.91674798450205!3d42.30573424652321!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2a9beb157a29%3A0x83f04af25d0f7d3a!2sParks%20%26%20Rec%20Gastropub%20Sports%20Bar!5e0!3m2!1sen!2sca!4v1643133084896!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday - Thursday:',
            second: 'Friday - Saturday'
        },
        hoursTimes: {
            first: '11:00am to 11:00pm',
            second: '11:00am to Midnight'
        },
        openingHours: {
            sunday: '11:00',
            monday: '11:00',
            tuesday: '11:00',
            wednesday: '11:00',
            thursday: '11:00',
            friday: '11:00',
            saturday: '11:00'
        },
        closingHours: {
            sunday: '23:00',
            monday: '23:00',
            tuesday: '23:00',
            wednesday: '23:00',
            thursday: '23:00',
            friday: '23:59',
            saturday: '23:59'
        },
    },
    {
        id: 15,
        name: 'Shawarma Shack',
        category: 'Restaurant',
        subcategory: 'Middle Eastern',
        picturePath: '/images/shawarmashack.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644342612/businessPictures/ShawarmaShack/Shack1_d169cc.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644342612/businessPictures/ShawarmaShack/Shack2_z8veom.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644342612/businessPictures/ShawarmaShack/Shack3_brjfmm.jpg',
        description: '',
        phoneNumber: '519-972-1916',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: 'https://jubzi.com/restaurants/shawarmashack',
        facebookURL: 'https://www.facebook.com/Shawarma-Shack-331531830264120/',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: '3357 Walker Rd Unit 1A, Windsor, ON N8W 5J7',
        googleMapsLink: 'https://goo.gl/maps/TfU4Sm9skhxzoJAF7',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2952.070311081296!2d-82.98057258450282!3d42.27702244833409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2becb68cc5bb%3A0x79de6f288ae801ab!2sShawarma%20Shack!5e0!3m2!1sen!2sca!4v1643132604196!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Saturday:'
        },
        hoursTimes: {
            first: 'CLOSED',
            second: '11:00am to 9:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: '11:00',
            tuesday: '11:00',
            wednesday: '11:00',
            thursday: '11:00',
            friday: '11:00',
            saturday: '11:00'
        },
        closingHours: {
            sunday: 'Closed',
            monday: '21:00',
            tuesday: '21:00',
            wednesday: '21:00',
            thursday: '21:00',
            friday: '21:00',
            saturday: '21:00'
        },
    },
    {
        id: 14,
        name: 'Spago',
        category: 'Restaurant',
        subcategory: 'Italian',
        picturePath: '/images/spago.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644342745/businessPictures/Spago/Spago1_scouxv.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644342745/businessPictures/Spago/Spago2_fyhxdl.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644342745/businessPictures/Spago/Spago3_kj6j4l.jpg',
        description: '',
        phoneNumber: '519-252-2233',
        phoneNumberDescription: '(Little Italy)',
        location1Name: 'Little Italy',
        phoneNumber2: '519-915-6469',
        phoneNumberDescription2: '(South Windsor)',
        location2Name: 'South Windsor',
        publicEmail: '',
        websiteURL: 'https://www.spago.ca',
        facebookURL: 'https://www.facebook.com/spagowindsor/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/spagocanada/',
        instagramUsername: 'spagocanada',
        instagramURL2: '',
        instagramUsername2: '',
        address: '690 Erie St E, Windsor, ON N9A 3X7',
        googleMapsLink: 'https://goo.gl/maps/dNB8sqbgxxXG1nN3A',
        address2: '3850 Dougall Ave, Windsor, ON N9G 1X2',
        googleMapsLink2: 'https://goo.gl/maps/oYpyN6E8ygWSZfLf7',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.4065169804658!2d-83.02638118450189!3d42.31252744609467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2cef72f3a599%3A0xb2c95aebdf6cec28!2s690%20Erie%20St%20E%2C%20Windsor%2C%20ON%20N9A%203X9!5e0!3m2!1sen!2sca!4v1643131178974!5m2!1sen!2sca',
        embeddedMapsLink2: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2953.031055538422!2d-83.00483688450329!3d42.25650934962722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2ea8f5261cf7%3A0x67481a5585eda3a2!2s3850%20Dougall%20Ave%2C%20Windsor%2C%20ON%20N9G%201X2!5e0!3m2!1sen!2sca!4v1643131234000!5m2!1sen!2sca',
        hasHours: true,
        hasMultipleLocations: true,
        locationsHaveDifferentHours: true,
        hoursDays: {
            first: 'Monday - Saturday:',
            second: 'Sunday:'
        },
        hoursTimes: {
            first: '12:00pm to 8:00pm',
            second: '1:00pm to 8:00pm'
        },
        hoursDaysLocation2: {
            first: 'Sunday - Thursday:',
            second: 'Friday - Saturday:'
        },
        hoursTimesLocation2: {
            first: '3:30pm to 9:00pm',
            second: '3:30pm to 11:00pm'
        },
        openingHours: {
            sunday: '13:00',
            monday: '12:00',
            tuesday: '12:00',
            wednesday: '12:00',
            thursday: '12:00',
            friday: '12:00',
            saturday: '12:00'
        },
        closingHours: {
            sunday: '20:00',
            monday: '20:00',
            tuesday: '20:00',
            wednesday: '20:00',
            thursday: '20:00',
            friday: '20:00',
            saturday: '20:00'
        },
    },
    {
        id: 13,
        name: 'Milos Greek Grill',
        category: 'Restaurant',
        subcategory: 'Greek',
        picturePath: '/images/milos.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644342897/businessPictures/Milos/Milos1_eavtxi.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644342897/businessPictures/Milos/Milos2_wzjcut.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644342897/businessPictures/Milos/Milos3_otr5ze.jpg',
        description: '',
        phoneNumber: '519-258-6363',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: 'https://www.milosgreekgrill.ca',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/milosgreekgrill/',
        instagramUsername: 'milosgreekgrill',
        instagramURL2: '',
        instagramUsername2: '',
        address: '1840 Wyandotte St E, Windsor, ON',
        googleMapsLink: 'https://goo.gl/maps/ioU95NgnZeKAtKuT8',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.9319183598777!2d-83.01606488450157!3d42.32265084545572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2d56335af141%3A0xad0e97d944b0963!2sMilos%20Greek%20Grill!5e0!3m2!1sen!2sca!4v1643130256675!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Tuesday:',
            second: 'Wednesday - Sunday:',
            third: 'Monday'
        },
        hoursTimes: {
            first: '4:00pm to 11:00pm',
            second: '11:30am to 11:00pm',
            third: 'CLOSED'
        },
        openingHours: {
            sunday: '11:30',
            monday: 'Closed',
            tuesday: '16:00',
            wednesday: '11:30',
            thursday: '11:30',
            friday: '11:30',
            saturday: '11:30'
        },
        closingHours: {
            sunday: '10:00',
            monday: 'Closed',
            tuesday: '23:00',
            wednesday: '23:00',
            thursday: '23:00',
            friday: '23:00',
            saturday: '23:00'
        },
    },
    {
        id: 12,
        name: 'The Penalty Box',
        category: 'Restaurant',
        subcategory: 'Casual',
        picturePath: '/images/thepenaltybox.png',
        description: '',
        phoneNumber: '519-253-3310',
        phoneNumberDescription: '(Walker)',
        location1Name: 'Walker',
        phoneNumber2: '226-674-0188',
        phoneNumberDescription2: '(Cabana) *NEW',
        location2Name: '*NEW Cabana',
        publicEmail: 'info@penaltyboxrestaurant.com',
        websiteURL: 'https://www.penaltyboxwindsor.com',
        facebookURL: '',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: '2151 Walker Rd, Windsor, ON N8W 3P5',
        googleMapsLink: 'https://goo.gl/maps/UkweVsz3TPfEUp2V7',
        address2: '525 Cabana Rd E, Windsor, ON N9G 1A5',
        googleMapsLink2: 'https://goo.gl/maps/WRPDhWdchcAxrrpW6',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.909322047103!2d-82.99665078450214!3d42.30180024677136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2c81eb2c074f%3A0xea60afcbcf302f46!2s2151%20Walker%20Rd%2C%20Windsor%2C%20ON%20N8W%201M4!5e0!3m2!1sen!2sca!4v1643043312164!5m2!1sen!2sca',
        embeddedMapsLink2: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2473.091307932382!2d-82.99714320984192!3d42.25859412767268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2eaa99a73947%3A0x53b241494387e0db!2s525%20Cabana%20Rd%20E%2C%20Windsor%2C%20ON%20N9G%201A5!5e0!3m2!1sen!2sca!4v1643043439583!5m2!1sen!2sca',
        hasHours: true,
        hasMultipleLocations: true,
        locationsHaveDifferentHours: true,
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Saturday:'
        },
        hoursTimes: {
            first: 'Closed',
            second: '10:30am to 8:00pm'
        },
        hoursDaysLocation2: {
            first: 'Sunday - Monday:',
            second: 'Tuesday - Saturday:'
        },
        hoursTimesLocation2: {
            first: 'Closed',
            second: '10:30am to 8:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: '10:30',
            tuesday: '10:30',
            wednesday: '10:30',
            thursday: '10:30',
            friday: '10:30',
            saturday: '10:30'
        },
        closingHours: {
            sunday: 'Closed',
            monday: '20:00',
            tuesday: '20:00',
            wednesday: '20:00',
            thursday: '20:00',
            friday: '20:00',
            saturday: '20:00'
        },
    },
    {
        id: 11,
        name: 'Riviera Pizza & Spaghetti House',
        category: 'Restaurant',
        subcategory: 'Italian',
        picturePath: '/images/rivierapizza.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644343059/businessPictures/RivieraPizza/RP1_fa3r0e.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644343059/businessPictures/RivieraPizza/RP2_k1wigv.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644343059/businessPictures/RivieraPizza/RP3_i5hbcp.jpg',
        description: '',
        phoneNumber: '519-945-2331',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: 'https://www.rivierapizza.ca',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/riviera_za/',
        instagramUsername: 'riviera_za',
        instagramURL2: '',
        instagramUsername2: '',
        address: '5760 Wyandotte St E, Windsor, ON N8S 1M6',
        googleMapsLink: 'https://goo.gl/maps/KCAJChp4r8oBqgd16',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.6392390983697!2d-82.97024428450143!3d42.32889284506184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2b36ef57a0b1%3A0x7327bd18c942f4df!2sRiviera%20Pizza%20%26%20Spaghetti%20House!5e0!3m2!1sen!2sca!4v1643049761175!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday:',
            third: 'Tuesday - Thursday:',
            fourth: 'Friday - Saturday'
        },
        hoursTimes: {
            first: '2:00pm to 9:00pm',
            second: '11:00am to 9:00pm',
            third: '11:00am to 10:00pm',
            fourth: '11:00am to 11:00pm'
        },
        openingHours: {
            sunday: '14:00',
            monday: '11:00',
            tuesday: '11:00',
            wednesday: '11:00',
            thursday: '11:00',
            friday: '11:00',
            saturday: '11:00'
        },
        closingHours: {
            sunday: '21:00',
            monday: '21:00',
            tuesday: '22:00',
            wednesday: '22:00',
            thursday: '22:00',
            friday: '23:00',
            saturday: '23:00'
        },
    },
    {
        id: 10,
        name: 'Valentina Salon',
        category: 'Health & Beauty',
        subcategory: 'Hair Salon',
        picturePath: '/images/valentinasalon.png',
        description: '',
        phoneNumber: '519-252-5780',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: 'http://valantinasalon.com',
        facebookURL: 'https://www.facebook.com/valantinasalon/',
        etsyURL: '',
        instagramURL: '',
        instagramUsername: '',
        instagramURL2: '',
        instagramUsername2: '',
        address: '3580 Tecumseh Rd E #2, Windsor, ON N8W 0A5',
        googleMapsLink: 'https://goo.gl/maps/ihPUZDEftYV3135ZA',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.7505262784157!2d-82.98329128450203!3d42.30518834655751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2b7de132c14f%3A0x2f7136b133287375!2sValantina%20Salon!5e0!3m2!1sen!2sca!4v1642699961748!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday - Monday:',
            second: 'Tuesday:',
            third: 'Wednesday - Thursday:',
            fourth: 'Friday',
            fifth: 'Saturday'
        },
        hoursTimes: {
            first: 'Closed',
            second: '9:30am to 3:00pm',
            third: '9:30am to 5:30pm',
            fourth: '9:30am to 7:00pm',
            fifth: '9:30am to 5:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: '09:30',
            wednesday: '09:30',
            thursday: '09:30',
            friday: '09:30',
            saturday: '09:30'
        },
        closingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: '15:00',
            wednesday: '17:30',
            thursday: '17:30',
            friday: '19:00',
            saturday: '17:00'
        },
    },
    {
        id: 9,
        name: 'Nancy Johns Gallery',
        category: 'Shopping',
        subcategory: 'Framing and Art',
        picturePath: '/images/nancyjohnsgallery.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644343407/businessPictures/NancyJohns/NJ1_fuz7pv.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644343408/businessPictures/NancyJohns/NJ2_zxqr1o.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644343408/businessPictures/NancyJohns/NJ3_zqh8ct.jpg',
        description: 'Nancy Johns Gallery & Framing is a commercial art gallery, with custom picture framing services. We represent fine art from Windsor/Essex County and provide the city\'s only art rental program.',
        phoneNumber: '519-945-2222',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'nancy@nancyjohns.com',
        websiteURL: 'https://www.nancyjohns.com',
        facebookURL: 'https://www.facebook.com/nancyjohnsgallery',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/nancyjohnsgallery/',
        instagramUsername: 'nancyjohnsgallery',
        instagramURL2: '',
        instagramUsername2: '',
        address: '4755 Wyandotte St E, Windsor, ON N8Y 1H8',
        googleMapsLink: 'https://goo.gl/maps/p5pKiezHUttTf9yg6',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.7601646094004!2d-82.9817573845015!3d42.326313945224655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2b458060690f%3A0x7c27c27b9d2055e5!2sNancy%20Johns%20Gallery%20%26%20Framing!5e0!3m2!1sen!2sca!4v1640644420421!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday - Monday:',
            second: 'Tuesday - Friday:',
            third: 'Saturday:'
        },
        hoursTimes: {
            first: 'Closed',
            second: '10:00am to 6:00pm',
            third: '10:00am to 4:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: '10:00',
            wednesday: '10:00',
            thursday: '10:00',
            friday: '10:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: 'Closed',
            monday: 'Closed',
            tuesday: '18:00',
            wednesday: '18:00',
            thursday: '18:00',
            friday: '18:00',
            saturday: '16:00'
        },
    },
    {
        id: 8,
        name: 'DeMarco\'s Fine Foods',
        category: 'Food',
        subcategory: 'Grocery Store',
        picturePath: '/images/demarcosfinefoods.jpeg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644343549/businessPictures/DeMarcos/DM1_xedjb0.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644343549/businessPictures/DeMarcos/DM2_erimtw.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644343549/businessPictures/DeMarcos/DM3_phbh7f.jpg',
        description: '',
        phoneNumber: '519-969-7887',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: 'https://www.demarcosfinefoods.com',
        facebookURL: 'https://www.facebook.com/demarcosfinefoods/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/demarcosfinefoods/',
        instagramUsername: 'demarcosfinefoods',
        instagramURL2: '',
        instagramUsername2: '',
        address: '1349 Grand Marais Rd W, Windsor, ON N9E 1E2',
        googleMapsLink: 'https://goo.gl/maps/iLQacLPVk3FFqKPZ8',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2952.5018781982026!2d-83.02871048450302!3d42.267808948915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2c2f9c35f17b%3A0xca5d55e263c8fd19!2sDeMarco%27s%20Fine%20Foods!5e0!3m2!1sen!2sca!4v1640657531167!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Monday:',
            second: 'Tuesday - Friday:',
            third: 'Saturday:',
            fourth: 'Sunday:'
        },
        hoursTimes: {
            first: 'Closed',
            second: '10:00am to 6:00pm',
            third: '10:00am to 5:00pm',
            fourth: '10:00am to 3:00pm',
        },
        openingHours: {
            sunday: '10:00',
            monday: 'Closed',
            tuesday: '10:00',
            wednesday: '10:00',
            thursday: '10:00',
            friday: '10:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: '15:00',
            monday: 'Closed',
            tuesday: '18:00',
            wednesday: '18:00',
            thursday: '18:00',
            friday: '18:00',
            saturday: '17:00'
        },
    },
    {
        id: 7,
        name: 'The Cutting Board Deli',
        category: 'Food',
        subcategory: 'Deli',
        picturePath: '/images/thecuttingboarddeli.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644343650/businessPictures/TheCuttingBoard/Board1_h5clk8.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644343650/businessPictures/TheCuttingBoard/Board2_metwmp.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644343650/businessPictures/TheCuttingBoard/Board3_sus85h.jpg',
        description: '',
        phoneNumber: '519-979-4406',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: '',
        facebookURL: 'https://www.facebook.com/thecuttingboarddeli.tecumseh/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/thecuttingboarddeli/',
        instagramUsername: 'thecuttingboarddeli',
        instagramURL2: '',
        instagramUsername2: '',
        address: '14301 Tecumseh Rd E, Windsor, ON N8N 1M3',
        googleMapsLink: 'https://goo.gl/maps/WaHtWQoSxJime4NTA',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.3647072074837!2d-82.84742788450187!3d42.3134193460382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883ad5401226512d%3A0xa36876a3cc69d6f!2sThe%20Cutting%20Board%20Deli%20Tecumseh!5e0!3m2!1sen!2sca!4v1641484812596!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday:',
            third: 'Tuesday - Saturday:'
        },
        hoursTimes: {
            first: '10:00am to 2:00pm',
            second: 'Closed',
            third: '10:00am to 4:00pm'
        },
        openingHours: {
            sunday: '10:00',
            monday: 'Closed',
            tuesday: '10:00',
            wednesday: '10:00',
            thursday: '10:00',
            friday: '10:00',
            saturday: '10:00'
        },
        closingHours: {
            sunday: '14:00',
            monday: 'Closed',
            tuesday: '16:00',
            wednesday: '16:00',
            thursday: '16:00',
            friday: '16:00',
            saturday: '16:00'
        },
    },
    {
        id: 6,
        name: 'The Barber\'s In',
        category: 'Health & Beauty',
        subcategory: 'Barber Shop',
        picturePath: '/images/thebarbersin.png',
        description: '',
        phoneNumber: '519-944-1315',
        phoneNumberDescription: '(Central Windsor)',
        location1Name: 'Central Windsor',
        phoneNumber2: '519-944-1315',
        phoneNumberDescription2: '(Riverside)',
        location2Name: 'Riverside',
        publicEmail: '',
        websiteURL: '',
        facebookURL: 'https://www.facebook.com/thebarbersin/',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/thebarbersinwindsor/',
        instagramUsername: 'thebarbersinwindsor',
        instagramURL2: '',
        instagramUsername2: '',
        address: '5124 Tecumseh Rd E, Windsor, ON N8T 1C1',
        googleMapsLink: 'https://goo.gl/maps/ns5usAMKJcYpDpw5A',
        address2: '12325 Riverside Dr E, Windsor, ON N8N 1A3',
        googleMapsLink2: 'https://goo.gl/maps/GRQ4FBNfdvJqVmZT7',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.600091739068!2d-82.96589148450194!3d42.308397846355135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2b110046c187%3A0xe07d28ba73f468d2!2s5124%20Tecumseh%20Rd%20E%2C%20Windsor%2C%20ON%20N8T%201C1!5e0!3m2!1sen!2sca!4v1641922926722!5m2!1sen!2sca',
        embeddedMapsLink2: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1080.827644627133!2d-82.89106497451371!3d42.33188205951131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88252ab499f12b47%3A0x291fac191da4736a!2s12325%20Riverside%20Dr%20E%2C%20Windsor%2C%20ON%20N8N%201A3!5e0!3m2!1sen!2sca!4v1641923147011!5m2!1sen!2sca',
        hasHours: true,
        hasMultipleLocations: true,
        locationsHaveDifferentHours: false,
        hoursDays: {
            first: 'Sunday:',
            second: 'Monday - Saturday:'
        },
        hoursTimes: {
            first: 'Closed',
            second: '9:00am to 6:00pm'
        },
        openingHours: {
            sunday: 'Closed',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: 'Closed',
            monday: '18:00',
            tuesday: '18:00',
            wednesday: '18:00',
            thursday: '18:00',
            friday: '18:00',
            saturday: '18:00'
        },
    },
    {
        id: 5,
        name: 'Bella Armande Jewelry',
        category: 'Shopping',
        subcategory: 'Jewelry',
        picturePath: '/images/bellaarmande.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644343983/businessPictures/BellaArmande/BA1_yaeuvg.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644343983/businessPictures/BellaArmande/BA2_r8o9yz.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644343983/businessPictures/BellaArmande/BA3_jigdve.jpg',
        description: '',
        phoneNumber: '',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: '',
        facebookURL: '',
        etsyURL: 'https://www.etsy.com/ca/shop/BellaArmandeJewelry',
        instagramURL: 'https://www.instagram.com/bellaarmandejewelry/',
        instagramUsername: 'bellaarmandejewelry',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: '',
        embeddedMapsLink2: '',
        hasHours: false,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 4,
        name: 'Felipe\'s Blazin BBQ',
        category: 'Food',
        subcategory: 'Sauces',
        picturePath: '/images/felipebbq.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644344187/businessPictures/FelipeBBQ/FB1_qkmdzh.webp',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644344187/businessPictures/FelipeBBQ/FB2_hbdunm.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644344187/businessPictures/FelipeBBQ/FB3_jdgllg.jpg',
        description: 'Felipe\'s Blazin BBQ  is a small local business based out of LaSalle, Ontario. We began this journey in November of 2020 with a passion of barbecue and a dream to provide the community with our new and creative flavours.',
        phoneNumber: '',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'felipesblazinbbq@gmail.com',
        websiteURL: 'https://www.felipesblazinbbq.com',
        facebookURL: 'https://www.facebook.com/felipesblazinbbq',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/felipesblazinbbq/',
        instagramUsername: 'felipesblazinbbq',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Lasalle, ON',
        googleMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: '',
        embeddedMapsLink2: '',
        hasHours: false,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 3,
        name: 'Threedee Lab',
        category: 'Shopping',
        subcategory: 'Gifts',
        picturePath: '/images/threedlab.png',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644344352/businessPictures/ThreedeeLab/3d-1_gcpzqu.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644344352/businessPictures/ThreedeeLab/3d-2_q7la5r.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644344352/businessPictures/ThreedeeLab/3d-3_ojkevn.jpg',
        description: '',
        phoneNumber: '',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: '',
        websiteURL: '',
        facebookURL: '',
        etsyURL: 'https://www.etsy.com/ca/shop/MikesThreeDeeLab?ref=simple-shop-header-name&listing_id=954504717',
        instagramURL: 'https://www.instagram.com/threedee_lab/',
        instagramUsername: 'threedee_lab',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: '',
        embeddedMapsLink2: '',
        hasHours: false,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 2,
        name: 'Manual Therapy Clinic',
        category: 'Health & Beauty',
        subcategory: 'Massage Therapy',
        picturePath: '/images/themtclinic.png',
        description: 'Online booking available for clinic, by appointment only. In home massage therapy also available. Inquire within.',
        phoneNumber: '226-260-2927',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'nwagnerrmt@gmail.com',
        websiteURL: 'https://mtc.janeapp.com',
        facebookURL: '',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/nwagner.rmt/',
        instagramUsername: 'nwagner.rmt',
        instagramURL2: 'https://www.instagram.com/themtclinic/',
        instagramUsername2: 'themtclinic',
        address: '1236 Tecumseh Rd E, Windsor, ON N8W 1B8',
        googleMapsLink: 'https://goo.gl/maps/US8QV5Un6o9F94RF9',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2950.988050317444!2d-83.00803438070346!3d42.30012040226445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2dea5459de3d%3A0x6e455a62b73c6b8!2sManual%20Therapy%20Clinic!5e0!3m2!1sen!2sca!4v1640657761775!5m2!1sen!2sca',
        embeddedMapsLink2: '',
        hasHours: true,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        hoursMessage: 'Please check the website link above for booking times.',
        hoursDays: {},
        hoursTimes: {},
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
    {
        id: 1,
        name: 'Glamour Events',
        category: 'Events',
        subcategory: 'Weddings and Events Decor',
        picturePath: '/images/glamourevents.jpg',
        businessPicture1: 'https://res.cloudinary.com/wertigo/image/upload/v1644344482/businessPictures/GlamourEvents/Glamour1_bzd72u.jpg',
        businessPicture2: 'https://res.cloudinary.com/wertigo/image/upload/v1644344482/businessPictures/GlamourEvents/Glamour2_tktbpy.jpg',
        businessPicture3: 'https://res.cloudinary.com/wertigo/image/upload/v1644344482/businessPictures/GlamourEvents/Glamour3_bmaac8.jpg',
        description: '',
        phoneNumber: '226-344-2490',
        phoneNumberDescription: '',
        location1Name: '',
        phoneNumber2: '',
        phoneNumberDescription2: '',
        location2Name: '',
        publicEmail: 'glam2events@yahoo.com',
        websiteURL: '',
        facebookURL: 'https://www.facebook.com/Glamour-Events-514389265312116',
        etsyURL: '',
        instagramURL: 'https://www.instagram.com/emma_glamourevents/',
        instagramUsername: 'emma_glamourevents',
        instagramURL2: '',
        instagramUsername2: '',
        address: 'Windsor, ON',
        googleMapsLink: '',
        address2: '',
        googleMapsLink2: '',
        embeddedMapsLink: '',
        embeddedMapsLink2: '',
        hasHours: false,
        hasMultipleLocations: false,
        locationsHaveDifferentHours: false,
        openingHours: {
            sunday: '09:00',
            monday: '09:00',
            tuesday: '09:00',
            wednesday: '09:00',
            thursday: '09:00',
            friday: '09:00',
            saturday: '09:00'
        },
        closingHours: {
            sunday: '17:00',
            monday: '17:00',
            tuesday: '17:00',
            wednesday: '17:00',
            thursday: '17:00',
            friday: '17:00',
            saturday: '17:00'
        },
    },
];