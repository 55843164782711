import './JumbotronHeader.css'

function JumbotronHeader() {
    return (
        <div className='Jumbotron'>
            <h1>Wertigo</h1>
            <h4>The local-friendly website for when you ask yourself where to go!</h4>
        </div>
    )
}

export default JumbotronHeader;